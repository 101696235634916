export default function ProjectPeerReviewSection() {
	return (
		<div className="py-2 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
			<h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl my-2">
				Peer Review
			</h2>
			<p className="text-xl leading-7 text-gray-500 mb-4">
			As mentioned previously, there will be a peer review component to the final project. Each student will be given a list of teams. You are responsible for watching the videos from those teams. A Google Form will be distibuted that you are responsible for filling out as part of the review. Additionally, you will need to ask at least one question on each of the Piazza posts from those teams. Since we will not be distributing the Google Form on this site, please keep on top of Piazza notifications. The peer review process will take place between December 10th, 2020 - December 12th, 2020.
			</p>
		</div>
	)
}