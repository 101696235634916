export default function ProjectIdeasSection() {
	return (
		<div className="py-2 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
			<h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl my-2">
				Project Ideas
			</h2>
			<p className="text-xl leading-7 text-gray-500 mb-4">
				Below is a link to several project ideas compiled by the course staff. The ideas enumerated in the document are intentionally brief: we want you to be creative with it. If you have any questions about the ideas, feel free to reach out over Piazza. You do not have to use any of these ideas for your project, but they can act as a nice starting point as you and your team map out what you want to work on.
			</p>
			<p className="text-xl leading-7 text-gray-500 mb-4">
				<a href="https://piazza.com/class/ksset4cralds5?cid=75" className="text-indigo-600">
					Download the list of project ideas here.
				</a>
			</p>
		</div>
	)
}